import SpeechController from './speech_controller';
import recognizedTextHandlerInstance from './helpers/speech_helper';
import speechHelper from './helpers/speech_helper';

export default class extends SpeechController {
  connect() {
    const text = this.element.innerText || this.element.value;
    speechHelper.appendRecognizedTextToInput(text);
    super.connect();
  }

  handleSimulateSpeechRecognitionEvent(event) {
    this.matchAndClick(
      this.element,
      event.detail.transcript,
      this.buttonClickedCallback,
    );
  }

  buttonClickedCallback(buttonElement) {
    buttonElement.click();
  }

  matchAndClick(element, recognizedText, callback) {
    let normalizedElementText = element.innerText.trim().toLowerCase();

    if (!normalizedElementText) {
      normalizedElementText = element.value.trim().toLowerCase();
    }

    const recognizedLowerText = recognizedText.toLowerCase();

    if (normalizedElementText === recognizedLowerText) {
      console.log('Match found for:', recognizedLowerText);

      if (callback) {
        callback(element);
      }
    } else {
      console.log(`[Voice Input]: No match found for"${recognizedLowerText}"`);
    }
  }
}
