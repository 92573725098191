import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    apiKey: String,
    init: { type: String, default: "true" },
  };

  static targets = [
    "checkbox",
    "add1",
    "add2",
    "city",
    "county",
    "postcode",
  ]

  connect() {
    if (this.initValue == "true") {
      initAutocomplete(this.apiKeyValue);
    }

    fillAddressOnSelect(this.apiKeyValue, this.inputFieldMapping())

    if (this.initValue == "true") {
      setInitialReadonlyState(this.checkboxTarget, this.fieldIdsToggledToReadOnly(), this.inputFieldMapping(), this.addressData())
    }

    enableReadOnlyFieldsOnCheck(this.checkboxTarget, this.fieldIdsToggledToReadOnly(), this.inputFieldMapping(), this.addressData())
  }

  inputFieldMapping() {
    const mapping = {};
    mapping[`#project_del_name`] = 'company';
    mapping[`#project_del_add1`] = 'line1';
    mapping[`#project_del_add2`] = 'line2';
    mapping[`#project_del_city`] = 'city';
    mapping[`#project_del_county`] = 'county';
    mapping[`#project_del_postcode`] = 'postcode';
    return mapping;
  }

  fieldIdsToggledToReadOnly() {
    return [
      `project_del_add1`,
      `project_del_add2`,
      `project_del_city`,
      `project_del_county`,
      `project_del_postcode`,
    ];
  }

  addressData() {
    return {
      company: $(`#project_del_name`).val(),
      line1: this.add1Target.value,
      line2: this.add2Target.value,
      city: this.cityTarget.value,
      county: this.countyTarget.value,
      postcode: this.postcodeTarget.value,
    }
  }
}
