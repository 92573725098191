import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['clearAndFocus'];

  connect() {
    this.clearAndFocusTarget.value = '';
    this.clearAndFocusTarget.focus();
  }
}
