import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    // Grab a reference to the toolbar(s) on the page.
    const toolbar = this.element.previousSibling
    const siblingH1 = toolbar.querySelector("[data-trix-attribute='heading1']")
    const headerNum = [5, 4, 3, 2];

    for (const num of headerNum) {
      siblingH1.insertAdjacentHTML("afterend", `<button type="button" class="trix-button" data-trix-attribute="heading${num}" title="Header ${num}">H${num}</button>`)
    }
  }
}
