const buildSettings = (settingsStr) => {
  const settings = {};
  let key;
  let value;

  const pairs = settingsStr.split(',');

  for (const pair of pairs) {
    [key, value] = pair.trim().split(':');
    if (typeof value === 'string') {
      value = value.trim();
    }
    settings[key.trim()] = value;
  }

  return settings;
};

export default {
  buildSettings,
};
