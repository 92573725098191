class SpeechQueueService {
  constructor() {
    if (SpeechQueueService.instance) {
      return SpeechQueueService.instance;
    }
    this.queue = [];
    SpeechQueueService.instance = this;
  }

  enqueue(message, messageTarget) {
    const utterance = new SpeechSynthesisUtterance(message);
    this.queue.push({ utterance, messageTarget });
    this.processQueue();
  }

  async processQueue() {
    const deviceIsSpeaking = speechSynthesis.speaking;

    if (this.queue.length > 0 && !deviceIsSpeaking) {
      const { utterance, messageTarget } = this.queue.shift();
      await this.speak(utterance, messageTarget).finally(() => {
        this.processQueue();
      });
    }
  }

  appendIcon(messageTarget) {
    const speakIcon = document.createElement('i');
    speakIcon.className = 'fa fa-volume-up';
    messageTarget.appendChild(speakIcon);
  }

  removeIcon(messageTarget) {
    const speakIcon = messageTarget.querySelector('.fa-volume-up');
    if (!speakIcon) return;

    setTimeout(() => {
      const existingSpeakIcon = messageTarget.querySelector('.fa-volume-up');
      if (existingSpeakIcon) {
        messageTarget.removeChild(existingSpeakIcon);
      }
    }, 2000);
  }

  async speak(utterance, messageTarget) {
    this.appendIcon(messageTarget);

    try {
      speechSynthesis.speak(utterance);

      return new Promise((resolve, reject) => {
        utterance.onend = () => {
          this.removeIcon(messageTarget);
          resolve();
        };

        utterance.onerror = (event) => {
          alert(`Speech synthesis failed: ${event.error}`);
          this.removeIcon(messageTarget);
          resolve(); // Ensure queue continues processing even on error
        };
      });
    } catch (error) {
      alert('An unexpected error occurred during speech synthesis:', error);
      this.removeIcon(messageTarget);
      throw error;
    } finally {
      this.removeIcon(messageTarget);
    }
  }
}

const speechQueueService = new SpeechQueueService();
export default speechQueueService;
