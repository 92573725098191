const RECOGNIZED_TEXT_LIST_KEY = 'recognizedTextList';
const DEVICE_IS_SPEAKING_KEY = 'deviceIsSpeaking';

class SpeechHelper {
  constructor() {
    if (SpeechHelper.instance) {
      return SpeechHelper.instance;
    }

    SpeechHelper.instance = this;
  }

  appendRecognizedTextToInput(recognizedText) {
    const existingListString = localStorage.getItem(RECOGNIZED_TEXT_LIST_KEY);
    let newList = [];

    if (existingListString) {
      newList = existingListString.split(',');
    }

    if (!newList.includes(recognizedText.toLowerCase())) {
      newList.push(recognizedText.toLowerCase());
    }

    localStorage.setItem(RECOGNIZED_TEXT_LIST_KEY, newList.join(','));
  }

  isTranscriptRecognized(transcript) {
    const existingListString = localStorage.getItem(RECOGNIZED_TEXT_LIST_KEY);

    if (existingListString) {
      const newList = existingListString.split(',');
      return newList.includes(transcript.toLowerCase());
    }

    return false;
  }

  setDeviceSpeakingStatus(status) {
    localStorage.setItem(DEVICE_IS_SPEAKING_KEY, status);
  }

  isDeviceSpeaking() {
    return localStorage.getItem(DEVICE_IS_SPEAKING_KEY) === 'true';
  }

  say(sentence) {
    const isDeviceSpeaking = localStorage.getItem(DEVICE_IS_SPEAKING_KEY);
    if (isDeviceSpeaking === 'true') {
      return;
    }

    try {
      new Promise((resolve) => {
        this.setDeviceSpeakingStatus(true);
        const utterance = new SpeechSynthesisUtterance(sentence);

        utterance.onend = () => {
          this.setDeviceSpeakingStatus(false);
          resolve();
        };

        utterance.onerror = (event) => {
          this.setDeviceSpeakingStatus(false);
        };

        speechSynthesis.speak(utterance);
      });
    } finally {
      this.setDeviceSpeakingStatus(false);
    }
  }
}

const speechHelper = new SpeechHelper();
Object.freeze(speechHelper);

export default speechHelper;
