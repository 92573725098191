// core-js & regenerator-runtime needed for react (TODO: remove imports and npm uninstall once react is removed)
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import '@hotwired/turbo-rails';

import '../controllers/index';
import flatpickr from 'flatpickr';

import Trix from "trix";
require("@rails/actiontext")
require("application/trix_customization")
