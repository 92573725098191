import { Controller } from '@hotwired/stimulus';
import speechQueueService from './helpers/speech_queue_service';

export default class extends Controller {
  static targets = ['message'];

  static values = {
    inputId: String,
    playOnPageLoad: Boolean,
  };

  connect() {
    this.message = this.messageTarget.innerText;
    if (this.playOnPageLoadValue) {
      setTimeout(() => {
        this.enqueueSpeech();
      }, 1000);
    }
  }

  repeat(evt) {
    evt.preventDefault();
    this.enqueueSpeech();
  }

  enqueueSpeech() {
    speechQueueService.enqueue(this.message, this.messageTarget);
  }

  focusOnInput() {
    $(`#${this.inputIdValue}`).focus();
  }
}
