import { Controller } from '@hotwired/stimulus';
import speechHelper from './helpers/speech_helper';

export default class extends Controller {
  connect() {
    this.initSpeechRecognition();
    window.addEventListener(
      'speechRecognitionResult',
    this.handleSimulateSpeechRecognitionEvent.bind(this),
    );
  }

  disconnect() {
    this.stopSpeechRecognition();
    window.removeEventListener(
      'speechRecognitionResult',
      this.handleSimulateSpeechRecognitionEvent.bind(this),
    );
  }

  handleSimulateSpeechRecognitionEvent() {
    throw new Error(
      'You must implement handleSimulateSpeechRecognitionEvent in the child class',
    );
  }

  initSpeechRecognition() {
    if (window.sharedRecognition) {
      return;
    }

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    const recognition = new SpeechRecognition();
    recognition.continuous = true;
    recognition.lang = 'en-GB';
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;

    speechHelper.setDeviceSpeakingStatus(false);

    recognition.onresult = (event) => {
      const deviceIsSpeaking = speechHelper.isDeviceSpeaking();

      if (deviceIsSpeaking) {
        return;
      }

      const recognizedText = event.results[
        event.results.length - 1
      ][0].transcript.trim();
      if (recognizedText === '') {
        return;
      }

      console.log('Speech Recognition Result:', recognizedText);
      this.dispatchCustomEvent('speechRecognitionResult', recognizedText);
    };

    recognition.onerror = (event) => {
      console.error('Speech Recognition Error:', event.error);
    };

    recognition.onend = () => {
      recognition.start();
    };

    window.sharedRecognition = recognition;
    recognition.start();
  }

  dispatchCustomEvent(eventName, detail) {
    const event = new CustomEvent(eventName, {
      detail: { transcript: detail },
    });
    window.dispatchEvent(event);
  }

  stopSpeechRecognition() {
    if (window.sharedRecognition) {
      window.sharedRecognition.stop();
      window.sharedRecognition = null;
    }
  }

  say(sentence) {
    speechHelper.say(sentence);
  }
}
