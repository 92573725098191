import { Controller } from '@hotwired/stimulus';
import helper from './helper';

export default class extends Controller {
  static targets = ['datepicker'];

  static values = {
    settings: { type: String, default: "startDate: '0d'" },
  };

  connect() {
    $(this.datepickerTarget).datepicker(
      helper.buildSettings(this.settingsValue),
    );
  }
}
