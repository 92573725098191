import { Controller } from '@hotwired/stimulus';
import Fuse from 'fuse.js';
import SpeechController from './speech_controller';
import speechHelper from './helpers/speech_helper';

export default class extends SpeechController {
  static values = {
    name: String,
  };

  inputs = [];

  totalRecognizedInput = 0;

  connect() {
    super.connect(this);

    this.inputs = $.map(
      $(`div[data-voice-input-name-value='${this.nameValue}']`).find('input'),
      this.inputFieldNames.bind(this),
    );

    this.initializeInputs();
    this.initFuzzyMatcher();
    this.focusFirstInput();
  }

  focusFirstInput() {
    const firstInput = this.element.querySelector('input');
    if (firstInput) {
      firstInput.focus();
    }
  }

  getFuzzyMatch(text) {
    const modifiedText = text
      .split(' ')
      .slice(1)
      .join(' ');

    const result = this.fuse.search(modifiedText);

    if (result.length > 0) {
      return result[0].item;
    }
    return null;
  }

  initFuzzyMatcher() {
    const options = {
      includeScore: true,
      threshold: 0.5,
    };

    this.fuse = new Fuse(this.inputs, options);
  }

  disconnect() {
    super.disconnect(this);
  }

  inputFieldNames(input) {
    return $(input)
      .attr('id')
      .replace(`${this.nameValue}_`, '')
      .replace('_', ' ');
  }

  initializeInputs() {
    for (const input of this.inputs) {
      const inputId = this.inputId(input);
      $(inputId).val('0');
    }
  }

  handleSimulateSpeechRecognitionEvent(event) {
    const { transcript } = event.detail;

    if (transcript !== '') {
      this.handleSpeechResult(transcript);
    }
  }

  handleSpeechResult(transcript) {
    this.totalRecognizedInput = 0;
    this.processTranscript(transcript);

    if (
      this.totalRecognizedInput == 0 &&
      !speechHelper.isTranscriptRecognized(transcript)
    ) {
      this.say('Unrecognized, please repeat');
    }
  }

  processTranscript(transcript) {
    const instructions = transcript.split(' and ');

    for (const instruction of this.extractAcceptableInput(instructions)) {
      this.processSingleInstruction(instruction);
    }
  }

  extractAcceptableInput(instructions) {
    return instructions
      .map((instruction) => this.parseAcceptableInput(instruction))
      .filter(
        (instruction) =>
          typeof instruction === 'string' || this.integerOnlyInput(instruction),
      );
  }

  processSingleInstruction(instruction) {
    if (this.integerOnlyInput(instruction)) {
      this.setValueOnActiveElement(instruction);
    } else {
      this.setValueOnSpecifiedLabel(instruction);
    }
  }

  isActiveElementMatching() {
    const { activeElement } = document;
    return this.inputs.some(
      (input) => activeElement.name === `${this.nameValue}[${input}]`,
    );
  }

  setValueOnActiveElement(value) {
    this.totalRecognizedInput++;
    if (this.isActiveElementMatching()) {
      document.activeElement.value = value;
      this.say(value);
    }
  }

  setValueOnSpecifiedLabel(instruction) {
    const parts = instruction.split(' ');
    const numberPart = this.wordToNumber(parts.shift());
    const labelPart = parts.join(' ');
    const inputId = this.inputId(labelPart);
    const target = $(inputId);
    const targetLen = target.length;

    if (targetLen === 1) {
      this.totalRecognizedInput++;
      target.val(numberPart);
      this.say(instruction);
    } else if (targetLen > 1) {
      alert(
        `Multiple elements uses the same ID '${inputId}'. Please inform DEV team.`,
      );
    } else {
      const isTranscriptRecognized = speechHelper.isTranscriptRecognized(
        instruction,
      );
      if (!isTranscriptRecognized) {
        alert(
          `Input element with ID '${inputId}' cannot be found. Please inform DEV team.`,
        );
      }
    }
  }

  wordToNumber(word) {
    if (this.integerOnlyInput(parseInt(word))) {
      return word;
    }

    const numberWords = [
      'zero',
      'one',
      'two',
      'three',
      'four',
      'five',
      'six',
      'seven',
      'eight',
      'nine',
      'ten',
    ];

    for (let idx = 0; idx <= 10; idx++) {
      if (word == numberWords[idx]) {
        return idx;
      }
    }
  }

  integerOnlyInput(value) {
    return Number.isInteger(value);
  }

  parseAcceptableInput(instruction) {
    const matchedLabel = this.getFuzzyMatch(instruction);
    if (matchedLabel) {
      const quantity = instruction.split(' ')[0];
      return `${quantity} ${matchedLabel}`;
    }

    const wordedNumber = this.wordToNumber(instruction);
    if (wordedNumber) {
      return this.integerValue(wordedNumber.toString());
    }
  }

  integerValue(instruction) {
    const words = instruction.split(' ').filter((word) => word !== '');
    const numbers = words.map((word) => parseInt(word));
    const nan = numbers.find((num) => isNaN(num));
    let value;

    if (nan === undefined) {
      value = parseInt(words.join(''));
    } else {
      value = numbers.pop();
    }

    return value;
  }

  inputId(input) {
    return `#${this.nameValue}_${input.replace(' ', '_')}`;
  }
}
