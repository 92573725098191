import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['output'];

  static values = {
    provider: String,
    video: String,
  };

  connect() {
    $('#videoModal')
      .on('shown.bs.modal', this.autoPlay.bind(this))
      .on('hidden.bs.modal', this.pauseVideo.bind(this));
  }

  loadVideo() {
    this.outputTarget.innerHTML = this.embedVideo();

    if (this.providerValue == "vidyard") {
      this.reloadPlayVidyardScript();
    }
  }

  autoPlay() {
    // No handling for Youtube because it autoplays
    if (this.providerValue == "vidyard") {
      setTimeout(function() {
        Vidyard.players()[this.videoValue].play();
      }.bind(this), 500);
    }
  }

  embedVideo() {
    if (this.providerValue == "youtube") {
      return this.embedYoutube();
    } else if (this.providerValue == "vidyard") {
      return this.embedVidyard();
    }
  }

  embedVidyard() {
    return `<img style='width: 100%; margin: auto; display: block;' class='vidyard-player-embed' src='https://play.vidyard.com/${this.videoValue}.jpg' data-uuid='${this.videoValue}' data-v='4' data-type='inline' />`;
  }

  embedYoutube() {
    return `<iframe class='player_iframe' width='100%' height='650px' src='https://www.youtube.com/embed/${this.videoValue}?enablejsapi=1&autoplay=1' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowfullscreen></iframe>`;
  }

  pauseVideo() {
    if (this.providerValue == "youtube") {
      this.pauseYoutube();
    } else if (this.providerValue == "vidyard") {
      Vidyard.players()[this.videoValue].pause();
    }
  }

  pauseYoutube() {
    const selection = document.getElementsByTagName('iframe');
    const iframes = Array.prototype.slice.call(selection);

    iframes.forEach(function(iframe) {
      iframe.contentWindow.postMessage(`{"event":"command","func":"pauseVideo","args":""}`, '*');
    });
  }

  reloadPlayVidyardScript() {
    const vidyard_js = document.getElementById('play-vidyard-com');
    if (vidyard_js) {
      vidyard_js.remove();
    }
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.src = 'https://play.vidyard.com/embed/v4.js';
    script.id = 'play-vidyard-com';
    head.appendChild(script);
  }
}
